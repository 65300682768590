import { Grid, Segment, Image } from 'semantic-ui-react';
import Foodtech from './assets/FoodTech.jpg';
import Gfi from './assets/Gfi.png';

const Featured = () => (
  <Grid.Row>
    <Grid.Column width={14} className='info-container'>
      <div className='info-title small-title poppins-font'>Featured</div>
      <Segment vertical>
        <Grid container stackable verticalAlign='bottom'>
          <Grid.Row>
            <Grid.Column width={8}>
              <Image rounded size='medium' src={Foodtech} style={{margin: 'auto'}} />
              <div className='poppins-font featured-desc'>
                Semifinalists at Foodtech World Cup by HackSummit in April 2024
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div style={{height: '300px', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <Image rounded size='medium' src={Gfi} style={{margin: 'auto'}} />
              </div>
              <div className='poppins-font featured-desc'>
                Reached the finals in selection to India Smart Protein Challenge 2023 of GFI
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Grid.Column>
  </Grid.Row>
);

export default Featured;