import React from 'react';
import Container from '../Container';
import { Grid, Image, ListItem, List, Header } from 'semantic-ui-react';
import './index.css';
import { PRODUCTS } from './data';

const index = () => {
  return (
    <Container className='products-container'>
      <div>
        <h1 className='product-heading poppins-font'>PRODUCTS - Sustainable ingredients for the future</h1>
      </div>
      {PRODUCTS.map((product) => (
        <div className={`product-wrapper ${product.className}`}>
          <h1 className='heading poppins-font'>{product.name}</h1>
          <div className='description-container'>
            <div className='description poppins-font'>{product.description}</div>
            <a href={product.link} className='learn-more poppins-font'>Learn More</a>
          </div>
        </div>
      ))}
      {PRODUCTS.map((product) => (
        <Grid centered stackable columns={2} style={{ padding: '4em 0' }}>
          <Grid.Row stretched>
            {product.isReverse ? <DataColumn product={product} /> : <ImageColumn image={product.image} />}
            {product.isReverse ? <ImageColumn image={product.image} /> : <DataColumn product={product} />}
          </Grid.Row>
        </Grid>
      ))}
    </Container>
  )
};

const ImageColumn = ({ image }) => (
  <Grid.Column width={6} verticalAlign='middle'>
    <Image src={image} verticalAlign='middle' rounded/>
  </Grid.Column>
);

const DataColumn = ({ product }) => (
  <Grid.Column width={6}>
    <div id={product.id}></div>
    <Header as={'h2'} className='poppins-font'>{product.name}</Header>
    <p style={{ fontSize: '1.33em', textAlign: 'justify' }} className='poppins-font'>
      {product.details}
    </p>
    <List bulleted style={{ fontSize: '1.33em', textAlign: 'justify' }}>
      {product.points.map((point) => (
        <ListItem className='poppins-font'>{point}</ListItem>
      ))}
    </List>
  </Grid.Column>
)

export default index;