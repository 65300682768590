import TerraMyco from './assets/TerraMyco.jpg';
import TerraFeed from './assets/TerraFeed.jpg';
import TerraCare from './assets/TerraCare.jpg';

export const TERRA_MYCO = {
  name: 'TerraMyco',
  description: 'Carbon-negative protein transforming CO2 into nutrients for sustainable health.',
  image: TerraMyco,
  id: 'terra-myco',
  link: '#terra-myco',
  className: 'ltr-wrapper',
  details: `TerraMyco is a highly nutritious organically produced protein. 
  It is the product of naturally occurring biological processes which mimic the plants in making food. 
  It takes in Carbon dioxide just like plants and produces nutrients like protein, fiber, omega fats & some vitamins. 
  We have developed this process to enable a faster and more efficient nutrient supply 
  which is also a carbon negative process as it takes carbon dioxide as its raw material.
  `,
  points: [
    'Non GMO – Allergen free – Chemical free',
    `Exhibits neutral flavors which can blend in various plant based meat analogues, alt dairy & confectionery products.`,
    `Can also be used to produce hybrid meat products or as a building block for 3D printed meat.`,
    `Provides all essential amino acids – Lysine, Methionine, Histidine, Threonine and more`,
    `Protein Digestibility corrected amino acid score as good as milk and better than animal meat`,
    `By consuming fermented protein based meat analogs you reduce chances of Antibiotic resistance, Zoonotic diseases & avoid cruelty to animals`,
    `High in Poly & Mono unsaturated fats & very less saturated fats – avoid cholesterol in your food`
  ]
};

export const TERRA_FEED = {
  name: 'TerraFeed',
  description: 'Eco-friendly feed with essential amino acids, a sustainable alternative to soymeal.',
  image: TerraFeed,
  id: 'terra-feed',
  link: '#terra-feed',
  className: 'rtl-wrapper',
  isReverse: true,
  details: `TerraFeed is a valuable substitute to traditional soymeal & fishmeal which leaves a massive carbon 
  footprint in its supply chain. Our feed comprises of essential amino acids like Lysine, Methionine, 
  Threonine, and Tryptophan. This feed can provide nutritional fortification to pets & cattle.`,
  points: [
    `Substitute to Fishmeal & soybean meal`,
    `Protein fortification for Pet food`,
    `Essential Amino Acids like Lysine, Methionine, Threonine, Tryptophan Cysteine`,
    `High & efficient nutrient ingredient for animal feed there by reducing methane emission`
  ]
};

export const TERRA_CARE = {
  name: 'TerraCare',
  description: 'Organic skin care with peptides, antioxidants, hydration, and anti-aging benefits.',
  image: TerraCare,
  id: 'terra-care',
  link: '#terra-care',
  className: 'ltr-wrapper',
  details: `TerraCare is a range of bioactive compounds containing peptides & antioxidants for skin care. 
  They also provide hydration to the skin & has anti-aging properties along with ability to treat pigmentation. 
  All of these are organic & produced in a carbon neutral processes unlike the existing chemicals in cosmetic industry 
  that are derived from crude oil & carbon intensive fossil energy.`,
  points: [
    `Bioactive compounds for hydration, pigmentation, anti-aging`,
    `Microbes based peptides & Antioxidants for skin care`,
    `Omega 3 Fatty acids`,
    `Organic Food colorant & Food supplements`,
  ]
};

export const PRODUCTS = [TERRA_MYCO, TERRA_FEED, TERRA_CARE];