import React, { useState } from "react";
import { InView } from 'react-intersection-observer';
import { Image, Container, Menu, Segment } from 'semantic-ui-react';
import HomepageHeading from '../Heading';
import Logo from './../../../assets/Logo.png';
import { Link } from 'react-router-dom';

const DesktopContainer = ({ children, Media }) => {
  const [fixed, setFixed] = useState(true);
  const toggleFixedMenu = () => setFixed(!fixed);

  return (
    <Media greaterThan='mobile'>
      <InView onChange={toggleFixedMenu}>
        <Segment className="home-desktop-menu" vertical>
          <Menu fixed={fixed ? 'top' : null} style={!fixed ? { borderBottom: 'none' }: {}} pointing={!fixed} secondary={!fixed} size='large'>
            <Container className="logo-container">
              <Menu.Item as={Link} to="/home" className="logo">
                <Image src={Logo} size="small" style={{ width: '200px'}} />
              </Menu.Item>
            </Container>
            <Container className="menu-container">
              <Menu.Item as={Link} to="/products" className="menu">Products</Menu.Item>
              <Menu.Item as={Link} to="/technology" className="menu">Technology</Menu.Item>
              <Menu.Item as={Link} to="/impact" className="menu">Impact</Menu.Item>
              <Menu.Item as={Link} to="/about-us" className="menu">About Us</Menu.Item>
            </Container>
          </Menu>
          <HomepageHeading />
        </Segment>
      </InView>
      {children}
    </Media>
  )
};

export default DesktopContainer;
