import React from "react";
import { Header } from 'semantic-ui-react';

const HomepageHeading = ({ mobile }) => (
  <div className="homepage-heading">
    <div>
      <div className="title-container">
        <Header
          as='h1'
          content='Harnessing Carbon to Enable Circular Economy'
          className="anton-regular"
          style={{
            fontSize: mobile ? '2em' : '4.25em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: mobile ? '1.5em' : '3em',
            color: mobile ? '#fff' : '#000',
          }}
        />
      </div>
    </div>
    <div className="subtitle-container">
      <div className="subtitle-wrapper">
        <Header
          as='h2'
          className="poppins-font"
          content='Terramatter aims at putting Carbon emissions to use to make critical & specialty chemicals 
            like Single cell Proteins (SCP) thereby creating a positive spiral of circular carbon economy.'
          style={{
            fontSize: mobile ? '1.5em' : '1.7em',
            fontWeight: 'normal',
            marginTop: mobile ? '0.5em' : '1.5em',
            color: mobile ? '#fff' : '#000',
          }}
        />
      </div>
    </div>
  </div>
);

export default HomepageHeading;