import React from 'react';
import { Input, Segment, Grid, TextArea, Form, Button, Header } from 'semantic-ui-react';

const ContactForm = () => {
  return (
    <Segment inverted style={{paddingTop: '4em', margin: 0}}>
      <Grid style={{padding: '4em 0'}} container stackable verticalAlign='middle' centered>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form>
              <Header as='h3' style={{ fontSize: '2em' }} inverted>Contact Us</Header>
              <Input fluid icon='mail' iconPosition='left' placeholder='Email' style={{marginBottom: '2em'}} />
              <Input fluid placeholder='Subject' style={{marginBottom: '2em'}} />
              <TextArea rows={6} fluid placeholder='Tell us more' style={{marginBottom: '2em'}} />
              <Button type='submit' primary>Send</Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default ContactForm;